import { postEmployerGQL, postODSEmployerGQL } from '../../api/octo/graphql'
import { GET_PAYMENT_METHODS_AND_FAILED_PAYMENTS_QUERY } from '../../api/octo/graphql/queries/billing'
import { GET_PROFILE_QUERY } from '../../api/octo/graphql/queries/profile'
import { GET_TOTAL_APPLICATIONS_QUERY } from '../../api/octo/graphql/queries/application'
import { GET_TOTAL_ODS_UNREAD_CHAT_COUNT_QUERY } from '../../api/octo/graphql/queries/ods'

export default {
  /**
   * Get payment methods and failed payments
   * @param {string} token
   */
  async getPaymentMethodsAndFailedPayments({ getters, commit }, token) {
    const companyID = getters.companyID
    const res = await postEmployerGQL(
      GET_PAYMENT_METHODS_AND_FAILED_PAYMENTS_QUERY,
      {
        company: companyID,
        platform: 'Stripe',
        status: ['Failed'],
      },
      token,
    )

    if (res && res.data) {
      // Set list of credit cards
      const paymentMethods = res.data.list_payment_method

      if (paymentMethods && Object.keys(paymentMethods).length > 0) {
        commit('updatePaymentMethods', {
          companyID,
          paymentMethods,
        })
      }

      // Set list of failed invoices
      const failedPayments = res.data.list_failed_payment
      if (failedPayments && Array.isArray(failedPayments.result)) {
        commit('updateFailedPayments', {
          companyID,
          failedPayments: failedPayments.result,
        })
      }
    }
  },

  /**
   * Get profile from Octo *graphql* endpoint
   * @param {string} token
   * @returns {*} Fetched user profile
   */
  async getProfile({ commit, dispatch }, token) {
    if (token) {
      // If token available,
      // send get profile request to Octo
      const res = await postEmployerGQL(GET_PROFILE_QUERY, {}, token)

      if (res) {
        if (
          res.data &&
          res.data.user_profile &&
          Array.isArray(res.data.user_profile.companies)
        ) {
          // Set user profile info to state
          commit('updateUserInfo', res.data.user_profile)

          // Set user display language
          commit(
            'updateUserDisplayLanguage',
            res.data.employer_setting.display_language,
          )

          // If company data available, set to state
          res.data.user_profile.companies.forEach((company) => {
            commit('updateCompany', company)
          })

          // Fetch unread application count
          dispatch('getUnreadApplicationCount', token)

          // Fetch payment methods and failed payments,
          // and set to state
          await dispatch('getPaymentMethodsAndFailedPayments', token)

          // Fetch unread chat count
          await dispatch('getTotalUnreadChatCount', token)

          // Return user profile data
          return res.data.user_profile
        } else if (res.error && res.error.status === 401) {
          // If token invalid (unauthorized),
          // clear token
          await dispatch('auth/setEmployerToken', null, { root: true })
        }
      }
    }
  },

  async getTotalUnreadChatCount({ getters, commit }, token) {
    const res = await postODSEmployerGQL(
      GET_TOTAL_ODS_UNREAD_CHAT_COUNT_QUERY,
      {},
      token,
    )

    if (res) {
      if (res.data && res.data.get_chat_unread_count) {
        const count = res.data.get_chat_unread_count.total_unread_count
        commit('updateTotalUnreadChatMessageCount', count)
      }
    }
  },

  /**
   * Get unread application count
   */
  async getUnreadApplicationCount({ getters, commit }, token) {
    const companyID = getters.companyID
    const res = await postEmployerGQL(
      GET_TOTAL_APPLICATIONS_QUERY,
      {
        company: companyID,
        is_reviewed: false,
      },
      token,
    )

    if (res) {
      if (res.data && res.data.list_application) {
        const count = res.data.list_application.total
        commit('updateUnreadApplicationCount', {
          companyID,
          count,
        })
      }
    }
  },
}
