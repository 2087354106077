/* eslint-disable sort-keys */
const FEATURE_FLAG = {
  ODS: {
    development: true,
    staging: true,
    beta: true,
    production: true,
  },
  CreditBalancePaymentMethod: {
    development: true,
    staging: true,
    beta: true,
    production: true,
  },
}

const getFeatureFlag = (feature) => {
  let env = process.env.EMPLOYER_WEB__ENVIRONMENT
  if (env.startsWith('pr-')) {
    env = 'staging'
  }
  return FEATURE_FLAG[feature][env]
}

export { getFeatureFlag }
