import {
  // OdsIsComingNotification,
  BankTransferLaunchedNotification,
  PaymentExpireSoonNotification,
  PaymentFailedNotification,
  PaymentIsExpiredNotification,
  // SummerJobPromotionNotification,
} from '../libs/mapping/notification'
import { getCookie, setCookie } from '../libs/cookies'
import { getLocalStorage, setLocalStorage } from '../libs/localStorage'
import cardStatus from '../libs/mapping/creditCardStatus'
import NotificationBar from '../components/header/appNotificationBar'
import NotificationType from '../libs/mapping/notificationType'

const NOTIFICATION_SEEN = 'SEEN'

/**
 *  Keys for local storage to store the seen status of the notification
 *
 *  [example]
 *  const MARKETING_SURVEY = 'has_seen_marketing_survey'
 */
const BANK_TRANSFER_LAUNCHED = 'has_seen_bank_transfer_launched'
const BIDDING_PROMOTE = 'has_seen_bidding_promotion'
const SCHEDULED_MAINTENANCE = 'has_seen_scheduled_maintenance'
const SUMMER_JOB_PROMOTION = 'has_seen_summer_job_promotion'
const ODS_IS_COMING = 'has_seen_ods_is_coming'

export default {
  components: {
    'notification-bar': NotificationBar,
  },
  computed: {
    /**
     * notification data to be displayed on the top banner
     *
     * [example]
     * else if (this.seenMarketingSurvey !== '20231106') {
     *  notification = MarketingSurveyNotification
     * }
     */
    notification() {
      const creditCardStatus = this.$store.getters['profile/creditCardStatus']
      const failedPayments = this.$store.getters['profile/failedPayments']
      const hasPaymentMethod = this.$store.getters['profile/hasPaymentMethod']

      let notification = {}
      if (failedPayments[0] && failedPayments[0].payment_url) {
        /**
         * Show payment failed banner if:
         * 1) company has failed payment, and
         * 2) payment URL is available
         */
        notification = {
          ...PaymentFailedNotification,
          buttonHref: failedPayments[0].payment_url,
          overlayHref: failedPayments[0].payment_url,
        }
      } else if (creditCardStatus === cardStatus.expireSoon) {
        // handle payment will expire
        notification = PaymentExpireSoonNotification
      } else if (creditCardStatus === cardStatus.expired) {
        // handle payment already expired
        notification = PaymentIsExpiredNotification
      } else if (
        !hasPaymentMethod &&
        this.seenBankTransferLaunched !== NOTIFICATION_SEEN
      ) {
        notification = BankTransferLaunchedNotification
      }
      // uncomment to enable summer job promotion banner
      // else if (this.seenSummerJobPromotion !== NOTIFICATION_SEEN) {
      //   notification = SummerJobPromotionNotification
      // }
      // uncomment to enable ods banner
      // else if (this.seenOdsIsComing !== NOTIFICATION_SEEN) {
      //   notification = OdsIsComingNotification
      // }

      return notification
    },

    /**
     * Whether to show notification bar at the top
     */
    showNotificationBar() {
      return Object.keys(this.notification).length > 0
    },
  },
  data() {
    return {
      /**
       * whether has seen the bidding banner or not
       *
       * [example]
       * `seenMarketingSurvey: getLocalStorage(MARKETING_SURVEY)`
       */
      seenBankTransferLaunched: getLocalStorage(BANK_TRANSFER_LAUNCHED),
      seenBiddingBanner: getLocalStorage(BIDDING_PROMOTE),
      seenOdsIsComing: getLocalStorage(ODS_IS_COMING),
      seenScheduledMaintenance: getCookie(SCHEDULED_MAINTENANCE),
      seenSummerJobPromotion: getLocalStorage(SUMMER_JOB_PROMOTION),
    }
  },
  methods: {
    /**
     * click event for the top banner on screen
     *
     * [example]
     * case NotificationType.marketingSurveyNotification:
     *   // update the local storage value
     *   setLocalStorage(MARKETING_SURVEY, '20231106')
     *   // dismiss the notification bar (normally, we use 'SEEN' as the value)
     *   this.seenMarketingSurvey = '20231106'
     *   window.open('https://forms.gle/Zo4k172x5s2X7DKs5', '_blank')
     *   break
     */
    notificationButtonAction() {
      const userDisplayLanguage = this.$store.getters['profile/displayLanguage']

      switch (this.notification.type) {
        case NotificationType.biddingPromotion:
          setLocalStorage(BIDDING_PROMOTE, NOTIFICATION_SEEN)
          this.seenBiddingBanner = NOTIFICATION_SEEN
          break
        case NotificationType.scheduledMaintenance:
          setCookie(SCHEDULED_MAINTENANCE, NOTIFICATION_SEEN, 14, false)
          this.seenScheduledMaintenance = NOTIFICATION_SEEN
          break
        case NotificationType.bankTransferLaunched:
          setLocalStorage(BANK_TRANSFER_LAUNCHED, NOTIFICATION_SEEN)
          this.seenBankTransferLaunched = NOTIFICATION_SEEN
          if (this.currentLocale.code === 'en') {
            window.open(
              'https://info.page.moovup.com/lp/sg703/faq-adbooster#bank_transfer',
              '_blank',
            )
          } else {
            window.open(
              'https://info.page.moovup.com/lp/sg703/faq-adbooster-cn#bank_transfer',
              '_blank',
            )
          }
          break
        case NotificationType.summerJobPromotionNotification:
          setLocalStorage(SUMMER_JOB_PROMOTION, NOTIFICATION_SEEN)
          this.seenSummerJobPromotion = NOTIFICATION_SEEN
          break
        case NotificationType.odsIsComing:
          setLocalStorage(ODS_IS_COMING, NOTIFICATION_SEEN)
          this.seenOdsIsComing = NOTIFICATION_SEEN
          if (userDisplayLanguage === 'en') {
            window.open(
              'https://ods-employer-intro-en.moovup.com?utm_medium=web&utm_source=employer_portal',
              '_blank',
            )
          } else {
            window.open(
              'https://ods-employer-intro-zh.moovup.com?utm_medium=web&utm_source=employer_portal',
              '_blank',
            )
          }
          break
      }
    },
  },
}
