import { postOcto } from './'

/**
 * Send POST change password request to Octo *REST* endpoint
 * @param {string} password
 * @param {string} passwordCurrent
 * @param {string} token
 */
export function changePassword(password, passwordCurrent, token) {
  return postOcto(
    'change-password',
    {
      original_password: passwordCurrent,
      password,
    },
    token,
  )
}

/**
 * Send POST confirm signup request to Octo *REST* endpoint,
 * returns set password token if success
 * @param {string} token
 */
export function confirmSignupEmail(token, displayLanguage) {
  return postOcto('confirm-signup', {
    display_language: displayLanguage,
    token,
  })
}

/**
 * Send POST forgot password request to Octo *REST* endpoint
 * @param {string} email
 */
export function forgotPassword(email) {
  return postOcto('request-forgot-password', { email })
}

/**
 * Send POST login request to Octo *REST* endpoint,
 * returns employer token if success
 * @param {string} email
 * @param {string} password
 */
export function loginEmail(email, password) {
  return postOcto('login', { email, password })
}

/**
 * Send POST login request to Octo *REST* endpoint,
 * returns employer token if success
 * @param {string} phone
 * @param {string} pin
 */
export function loginMobile(phone, pin, channel, displayLanguage) {
  return postOcto('employer-mfa-login', {
    channel,
    channel_id: phone,
    display_language: displayLanguage,
    token: Number(pin),
  })
}

/**
 * Send POST logout request to Octo *REST* endpoint,
 * with provided employer token
 * @param {string} token
 */
export function logout(token) {
  return postOcto('logout', null, token)
}

/**
 * Send POST reset password request to Octo *REST* endpoint
 * @param {string} password
 * @param {string} token
 */
export function resetPassword(password, token) {
  return postOcto('forgot-password', { password, token })
}

/**
 * Send POST set password request to Octo *REST* endpoint,
 * returns employer token if success
 * @param {string} password
 * @param {string} token
 */
export function setPassword(password, token) {
  return postOcto('set-password', { password, token })
}

/**
 * Send POST signup request to Octo *REST* endpoint
 * @param {string} email
 */
export function signupEmail(email, salesCode) {
  return postOcto('signup', {
    email,
    sales_code: salesCode,
  })
}

/**
 * Send POST request-mfa request to Octo *REST* endpoint
 * @param {string} displayLanguage
 * @param {string} channelId
 * @param {string} channel
 * @param {string} mode
 */
export function requestMfaToken(displayLanguage, channelId, channel, mode) {
  return postOcto('employer-request-mfa', {
    channel,
    channel_id: channelId,
    display_language: displayLanguage,
    mode,
  })
}

/**
 * Send POST request to update login method to Octo *REST* endpoint
 * @param {string} channel
 * @param {string} channelId
 * @param {string} displayLanguage
 * @param {string} platform
 * @param {string} token - access token
 */
export function requestUpdateLoginMethod(
  channel,
  channelId,
  displayLanguage,
  platform,
  token,
) {
  return postOcto(
    'request-update-provider-id',
    {
      channel,
      channel_id: channelId,
      display_language: displayLanguage,
      platform,
    },
    token,
  )
}

/**
 * Send POST send update phone login method request to Octo *REST* endpoint
 * @param {string} channel
 * @param {string} channelId
 * @param {string} pin verify code
 * @param {string} platform
 * @param {string} token - access token
 */
export function updateLoginMethodForPhone(
  channel,
  channelId,
  pin = '',
  platform,
  token,
) {
  return postOcto(
    'update-provider-id',
    {
      channel,
      channel_id: channelId,
      code: Number(pin),
      platform,
    },
    token,
  )
}

/**
 * Send POST send update email login method request to Octo *REST* endpoint
 * @param {string} channel
 * @param {string} emailToken jwt
 * @param {string} platform
 * @param {string} token - access token
 */
export function updateLoginMethodForEmail(
  channel,
  emailToken = '',
  platform,
  token,
) {
  return postOcto(
    'update-provider-id',
    {
      channel,
      platform,
      token: emailToken,
    },
    token,
  )
}


/**
 * Check if Cloudflare Challenge for MFA exists
 * returns boolean
 */
export async function isMfaChallengeExists() {
  // test for Cloudflare challenges
  const res = await postOcto('employer-mfa-login', {}, '')
  // only enable Cloudflare Turnstile if MFA challenge and
  // not the octo unauthenticated error
  return res.error && res.error.status !== 401
}
