/**
 * Locale keys for error messages
 */
export default {
  /** Checkbox required */
  CHECK_REQUIRED: 'checkRequired',

  /** Date string invalid */
  DATE_STR_INVALID: 'dateStrInvalid',

  /** Login failed (email / password incorrect) */
  E100038: 'loginFailed',

  /** At least one address required */
  E200001: 'addressRequired',

  /** At least one job type required */
  E200002: 'jobTypeRequired',

  /** Job state invalid */
  E200003: 'jobStateInvalid',

  /** Employment type invalid */
  E200004: 'employmentTypeInvalid',

  /** At least one written skill required */
  E200005: 'writtenSkillRequired',

  /** At least one spoken skill required */
  E200006: 'spokenSkillRequired',

  /** File type not accepted */
  E200007: 'fileTypeNotAccepted',

  /** Email address already registered */
  E200008: 'emailAlreadyRegistered',

  /** Email address invalid */
  E200009: 'emailInvalid',

  /** Access token invalid */
  E200010: 'tokenInvalid',

  /** Access token has expired */
  E200011: 'tokenExpired',

  /** Password already set */
  E200012: 'passwordAlreadySet',

  /** Company doesn't exist */
  E200013: 'companyNotExist',

  /** Operation not allowed */
  E200014: 'operationDenied',

  /** Application already rejected */
  E200016: 'applicationAlreadyRejected',

  /** Application doesn't exist */
  E200017: 'applicationNotExist',

  /** Address coordinates invalid */
  E200018: 'addressCoordinatesInvalid',

  /** Address already registered */
  E200019: 'addressAlreadyRegistered',

  /** Billing platform invalid */
  E200020: 'invalidBillingPlatform',

  /** Billing account already exists */
  E200021: 'billingAccountNotExist',

  /** Billing account doesn't exist */
  E200022: 'billingAccountAlreadyExists',

  /** Product type invalid */
  E200023: 'productTypeInvalid',

  /** Invalid job promotion budget */
  E200024: 'promotionBudgetInvalid',

  /** Promote job denied */
  E200025: 'promoteJobDenied',

  /** Edit job promotion failed */
  E200026: 'editJobPromotionFailed',

  /** Stop job promotion failed */
  E200027: 'stopJobPromotionFailed',

  /** Date range invalid */
  E200028: 'dateRangeInvalid',

  /** Invalid OTP */
  E200033: 'loginOtpInvalid',

  /** Invalid requestor */
  E200034: 'invalidRequestor',

  /** Mobile already registered */
  E200035: 'mobileAlreadyRegistered',

  /** Account doesn't exist */
  E200036: 'accountNotExist',

  /** Invalid MFA request mode */
  E200037: 'invalidMfaRequestMode',

  /** Fruad job post by fruad system */
  E200039: 'fraudJobPost',

  /** Under Review job post by system */
  E200040: 'reviewJobPost',

  /** Bank transfer submission error */
  E200052: 'bankTransferTopupNotAllowed',

  /** Email successfully resent */
  EMAIL_RESENT: 'emailResent',

  /** End date before start date */
  END_DATE_BEFORE_START_DATE: 'endDateBeforeStartDate',

  /** File not selected */
  FILE_NOT_SELECTED: 'fileNotSelected',

  /** File size exceeds limit */
  FILE_SIZE_EXCEED_LIMIT: 'fileSizeExceedLimit',

  /** Geolocation access denied */
  GEOLOCATION_DENIED: 'geolocationDenied',

  /** Geolocation not available */
  GEOLOCATION_NOT_AVAILABLE: 'geolocationNotAvailable',

  /** Selected list items count lower than limit */
  LIST_COUNT_BELOW_MIN: 'listCountBelowMin',

  /** Selected list requires at least one item */
  LIST_COUNT_BELOW_ONE: 'listCountBelowOne',

  /** Selected list items count greater than limit */
  LIST_COUNT_EXCEED_MAX: 'listCountExceedMax',

  /** Selected list invalid */
  LIST_INVALID: 'listInvalid',

  /** Invalid OTP */
  LOGIN_OTP_INVALID: 'loginOtpInvalid',

  /** Number lower than limit */
  NUMBER_BELOW_MIN: 'numberBelowMin',

  /** Number greater than limit */
  NUMBER_EXCEED_MAX: 'numberExceedMax',

  /** Number invalid */
  NUMBER_INVALID: 'numberInvalid',

  /** Number not following incremental step */
  NUMBER_NOT_FOLLOW_STEP: 'numberNotFollowStep',

  /** Number not greater than zero */
  NUMBER_NOT_GREATER_THAN_ZERO: 'numberNotGreaterThanZero',

  /** Password invalid */
  PASSWORD_INVALID: 'passwordInvalid',

  /** Wrong password */
  PASSWORD_WRONG: 'passwordWrong',

  /** Passwords not match */
  PASSWORDS_NOT_MATCH: 'passwordsNotMatch',

  /** Range maximum value not greater than minimum value */
  RANGE_MAX_NOT_GREATER_THAN_MIN: 'rangeMaxNotGreaterThanMin',

  /** Request error */
  REQUEST_ERROR: 'requestError',

  /** Field required */
  REQUIRED: 'required',

  /** Credit card declined */
  STRIPE_CARD_DECLINED: 'stripeCardDeclined',

  /** Credit card CVV/CVC incorrect */
  STRIPE_INCORRECT_CVC: 'stripeInvalidCvc',

  /** Credit card number incorrect */
  STRIPE_INCORRECT_NUMBER: 'stripeInvalidNumber',

  /** Credit card CVV/CVC invalid */
  STRIPE_INVALID_CVC: 'stripeInvalidCvc',

  /** Credit card expiry month invalid */
  STRIPE_INVALID_EXPIRY_MONTH: 'stripeInvalidExpiryMonth',

  /** Credit card expiry month in the past */
  STRIPE_INVALID_EXPIRY_MONTH_PAST: 'stripeInvalidExpiryDatePast',

  /** Credit card expiry year invalid */
  STRIPE_INVALID_EXPIRY_YEAR: 'stripeInvalidExpiryYear',

  /** Credit card expiry year in the past */
  STRIPE_INVALID_EXPIRY_YEAR_PAST: 'stripeInvalidExpiryDatePast',

  /** Credit card number invalid */
  STRIPE_INVALID_NUMBER: 'stripeInvalidNumber',

  /** Setup authentication failed */
  STRIPE_SETUP_INTENT_AUTHENTICATION_FAILURE:
    'stripeSetupIntentAuthenticationFailure',

  /** Telephone number invalid */
  TELEPHONE_INVALID: 'telephoneInvalid',

  /** Time string invalid */
  TIME_STR_INVALID: 'timeStrInvalid',

  /** Time string not following incremental step */
  TIME_STR_NOT_FOLLOW_STEP: 'timeStrNotFollowStep',

  /** Upload failed */
  UPLOAD_FAILED: 'uploadFailed',
}
