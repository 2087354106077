import Vue from 'vue'

/**
 * Profile *Vuex* module mutations
 */
export default {
  /**
   * Clear user profile from state
   */
  clearProfile(state) {
    state.companies = {}
    state.email = ''
    state.failedPayments = {}
    state.paymentMethods = {}
    state.telephone = ''
    state.totalUnreadApplicationCount = {}
  },

  /**
   * Read an application
   */
  readApplication(state, companyID) {
    if (
      state.totalUnreadApplicationCount[companyID] !== null &&
      state.totalUnreadApplicationCount[companyID] > 0
    ) {
      const count = state.totalUnreadApplicationCount[companyID] - 1
      Vue.set(state.totalUnreadApplicationCount, companyID, count)
    }
  },

  /**
   * Set company profile to state,
   * based on provided company ID
   */
  updateCompany(state, company) {
    if (company && company._id) {
      const _id = company._id
      // Add company to list if not available yet
      if (!state.companies[_id]) {
        Vue.set(state.companies, _id, { _id })
      }

      // Update company fields
      Object.keys(company).forEach((fieldName) => {
        if (fieldName !== '_id') {
          Vue.set(state.companies[_id], fieldName, company[fieldName])
        }
      })
    }
  },

  /**
   * Set failed payments to state
   * based on provided company ID
   * @param {string} companyID
   * @param {Array} failedPayments
   */
  updateFailedPayments(state, { companyID, failedPayments }) {
    if (Array.isArray(failedPayments)) {
      // Only set payments w/ payment URL
      Vue.set(
        state.failedPayments,
        companyID,
        failedPayments.filter((payment) => payment && payment.payment_url),
      )
    }
  },

  /**
   * Set payment methods to state,
   * as a map of payment methods under this company
   * based on provided company ID
   * @param {string} companyID
   * @param {Map} paymentMethods
   */
  updatePaymentMethods(state, { companyID, paymentMethods }) {
    Vue.set(state.paymentMethods, companyID, paymentMethods)
  },


  /**
   * Set unread chat message count in ODS
   */
  updateTotalUnreadChatMessageCount(state, count) {
    state.totalUnreadChatMessageCount = count
  },

  /**
   * Set unread application count
   */
  updateUnreadApplicationCount(state, { companyID, count = 0 } = {}) {
    Vue.set(state.totalUnreadApplicationCount, companyID, count)
  },

  /**
   * Set display language
   */
  updateUserDisplayLanguage(state, displayLanguage) {
    state.displayLanguage = displayLanguage
  },

  /**
   * Set user profile info to state
   */
  updateUserInfo(state, { email = '', telephone = '' } = {}) {
    state.email = email
    state.telephone = telephone
  },
}
