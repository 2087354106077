export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    // Define routes that should be full page redirect
    const fullPageRoutes = ['/login/', '/signup/', '/password/forgot/']

    // Get the base path
    const basePath = app.router.options.base || '/'

    // Only refresh if we're navigating from a different route (SPA navigation)
    // and not on initial page load or direct URL access
    if (
      fullPageRoutes.includes(to.path) && to.fullPath &&
      from.path && from.path !== to.path &&
      // avoid redirect loop, also not skipping the not found flow
      (from.name || from.path !== '/')
    ) {
      window.location.href = to.fullPath.startsWith('/') ?
        basePath + to.fullPath.slice(1) :
        basePath + to.fullPath
      return // Stop the navigation chain
    }

    next() // Continue with normal navigation
  })
}
