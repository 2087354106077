import cardStatus from '../../libs/mapping/creditCardStatus'
import { getDifferenceFromNow } from '../../libs/datetime'
/**
 * Profile *Vuex* module getters
 */
export default {
  /**
   * Default company BR Approved
   * @readonly
   */
  brApproved(state, getters) {
    const company = getters.defaultCompany
    if (company) {
      return company.br_approved
    }
    return false
  },
  /**
   * Default company BR cert
   * @readonly
   */
  brCert(state, getters) {
    const company = getters.defaultCompany
    if (company) {
      return company.business_registration_certificate || ''
    }
    return ''
  },

  /**
   * Default company contact email
   * @readonly
   */
  companyEmail(state, getters) {
    const company = getters.defaultCompany
    if (company) {
      return company.email || ''
    }
    return ''
  },

  /**
   * Default user accessible company ID,
   * i.e. first available accessible company
   * @readonly
   */
  companyID(state) {
    return Object.keys(state.companies)[0] || ''
  },

  /**
   * Default company logo
   * @readonly
   */
  companyLogo(state, getters) {
    const company = getters.defaultCompany
    if (company) {
      return company.company_logo_thumbnail || ''
    }
    return ''
  },

  /**
   * Default company name
   * @readonly
   */
  companyName(state, getters) {
    const company = getters.defaultCompany
    if (company) {
      return company.name || ''
    }
    return ''
  },

  /**
   * Whether default company is suspended
   * @readonly
   */
  companySuspended(state, getters) {
    const company = getters.defaultCompany
    if (company) {
      return !!company.is_suspended
    }
    return false
  },

  /**
   * For what reason is the company suspended
   * @readonly
   */
  companySuspendedReason(state, getters) {
    const company = getters.defaultCompany
    if (company) {
      return company.suspended_reason || ''
    }
    return ''
  },

  /**
   * Default company contact phone
   * @readonly
   */
  companyTelephone(state, getters) {
    const company = getters.defaultCompany
    if (company) {
      return company.telephone || ''
    }
    return ''
  },

  /**
   * All credit cards under this company
   * @readonly
   */
  creditCards(state, getters) {
    const paymentMethods = state.paymentMethods[getters.companyID]
    if (paymentMethods && paymentMethods.credit_cards) {
      return paymentMethods.credit_cards
    }
    return []
  },

  /**
   * output current credit card status
   * cardStatus.expireSoon: payment method will expire soon if difference is less than 60 days
   * cardStatus.expired: payment method is expired if difference is less than or equal to 0 days
   * @readonly
   */
  creditCardStatus(state, getters) {
    const paymentMethods = state.paymentMethods[getters.companyID]
    if (paymentMethods && paymentMethods.credit_cards && paymentMethods.credit_cards.length > 0) {
      const firstCard = paymentMethods.credit_cards[0]
      if (firstCard.expiry_date) {
        const difference = getDifferenceFromNow(firstCard.expiry_date)
        if (difference <= 0) {
          return cardStatus.expired
        } else if (difference < 60) {
          return cardStatus.expireSoon
        }
        return cardStatus.valid
      }
    }
    return cardStatus.unknown
  },

  /**
   * Default user accessible company
   * based on default company ID
   * @readonly
   */
  defaultCompany(state, getters) {
    const companyID = getters.companyID
    if (companyID) {
      return state.companies[companyID]
    }
    return null
  },
  displayLanguage(state) {
    return state.displayLanguage
  },
  /**
   * Failed payments issued to default company
   * @readonly
   */
  failedPayments(state, getters) {
    if (Array.isArray(state.failedPayments[getters.companyID])) {
      return state.failedPayments[getters.companyID]
    }
    return []
  },

  /**
   * Whether user contains a payment method under this company,
   * @readonly
   */
  hasPaymentMethod(state, getters) {
    const paymentMethods = state.paymentMethods[getters.companyID]
    if (!paymentMethods) return false

    const hasCreditCard = paymentMethods.credit_cards && paymentMethods.credit_cards.length > 0
    const hasCreditBalance = paymentMethods.credit_balance !== null

    return hasCreditCard || hasCreditBalance
  },

  /**
   * return all payment methods under this company.
   * @readonly
   */
  paymentMethods(state, getters) {
    if (
      state.paymentMethods &&
      state.paymentMethods[getters.companyID] &&
      Object.keys(state.paymentMethods[getters.companyID]).length > 0
    ) {
      return state.paymentMethods[getters.companyID]
    }
    return null
  },


  /**
   * Whether user profile is created,
   * i.e. default company profile is created
   * @readonly
   */
  isCreated(state, getters) {
    const company = getters.defaultCompany
    return (
      !!company &&
      !!company.name &&
      !!company.contact_person &&
      !!company.legal_business_name &&
      (getters.isEmailLogin ? !!company.telephone : true)
    )
  },

  /**
   * Whether user is email login
   * @readonly
   */
  isEmailLogin(state) {
    return !!state.email
  },

  /**
   * Whether user is phone login
   * @readonly
   */
  isPhoneLogin(state) {
    return !!state.telephone
  },

  /**
   * Profile's email
   */
  profileEmail(state) {
    return state.email
  },

  /**
   * Profile's telephone
   */
  profilTelephone(state) {
    return state.telephone
  },

  /**
   * Total unread application count
   * @readonly
   */
  totalUnreadApplicationCount(state, getters) {
    const companyID = getters.companyID
    if (companyID) {
      return state.totalUnreadApplicationCount[companyID] || 0
    }
    return 0
  },

  /**
   * Number of unread chat message in ODS
   */
  totalUnreadChatMessageCount(state) {
    return state.totalUnreadChatMessageCount
  },
}
